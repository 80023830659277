//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
// import VueSimpleContextMenu from 'vue-simple-context-menu'
import VueSimpleContextMenu from '~/components/vue-simple-context-menu'
import domel from '~/components/domel'
import InputClear from '~/components/input-clear'


var _singletonCounter = 0;

export default {
	name: 'collection-view',

	components:
	{
		'context-menu': VueSimpleContextMenu,
		domel,
		InputClear
	},

	props:
	{
		url:
		{
			type: String,
			default: ''
		},

		extraParams:
		{
			type: [Object, Array],
			required: false,
		},

		items:
		{
			type: Array,
			default: null
		},

		filterField:
		{
			type: String,
			default: 'name'
		},

		containerTag:
		{
			type: String,
			default: 'div'
		},

		itemTag:
		{
			type: String,
			default: 'div'
		},

		itemClass:
		{
			type: Object,
			default: () => ({})
		},

		itemStyle:
		{
			type: Object,
			default: () => ({})
		},

		keyProp:
		{
			type: String,
			default: 'id'
		}
	},

	data()
	{
		return {
			open: false,
			collection: null,
			selectedIndex: -1,
			resolve: null,
			reject: null,
			searchStr: '',
			singletonCounter: 0,
			menuOptions: [
				{ name: 'delete' }
			]
		}
	},

	computed:
	{
		...mapGetters(['lang', 'langShort', 'allLanguages']),

		refName()
		{
			return 'ctxtMenu' + this.singletonCounter;
		},

		idName()
		{
			return 'CollectionViewCtxtMenu' + this.singletonCounter;
		},
		// collection()
		// {
		// 	return this._collection || this.items;
		// }
		filteredCollection()
		{
			if (this.searchStr.trim().length > 0)
			{
				let regexp = new RegExp(this.searchStr.trim(), "i");
				return this.collection.filter( x => x[this.filterField].search(regexp) > -1 )
			}
			else
				return this.collection;
		}
	},

	created()
	{
		consola.debug('collection view created...')
		this.singletonCounter = ++_singletonCounter;

	},

	mounted()
	{
		consola.debug('collection view mounted...', this.singletonCounter)
		if ( this.url )
		{
			consola.debug('collection view loading data from ', this.url)
			const self = this;
			let params = { locale: this.lang };
			if (this.extraParams)
			{
				params = { ...params, ...this.extraParams };
			}
			this.$apiGet( this.url, params )
				.then( (response)=>
				{
					if (response.data)
					{
						const data = response.data;
						if (Array.isArray(data))
							this.collection = Object.freeze( data );
						else
						{
							this.collection = Object.freeze( [] );
							consola.debug(`${__filename}[259] error: `, data)
						}

						// consola.debug(`loaded "${this.url}": `, data, this.collection)

					}
					else if (response.error)
					{
						const error = response.error;
						consola.debug(`${__filename}[268] error: `, error)
						self.collection = null;
					}


				} )
				.catch( (error)=>
				{
					consola.debug(`${__filename}[276] error: `, error)
					return error;
				} )
		}
		else if (this.items)
		{
			this.collection = [...this.items];
		}
		else
		{
			console.error('collection view: no url given')
		}
	},

	methods:
	{
		showCtxtMenu(event, item)
		{
			consola.debug('showCtxtMenu: ', item)
			// debugger
			if ( this.url )
			{
				event.preventDefault();
				event.stopPropagation();
				// this.$refs.ctxtMenu.showMenu(event, item)
				this.$refs[this.refName].showMenu(event, item)
			}
		},

		handleMenuSelection(event)
		{
			consola.debug('handleMenuSelection: ', event)
			// if (this.url == 'media')
			{
				let file = '';
				let sendData = { locale: this.lang };
				let url = this.url;

				switch (this.url)
				{
					case 'media':
						{
							file = event.item.name.split('_')[1]
							sendData.file = event.item.name;
							break;
						}

					default:
						{
							let idProp = this.url.toLowerCase() + '_id';
							if ( typeof event.item[idProp] == 'undefined' )
								return;
							const id = event.item[idProp];
							file = event.item.name || event.item.title || id;
							url = this.url + '/' + id;
						}
				}

				if ( confirm(`Are you sure you want to delete [${file}]?\nyou can't undo this action.`) )
				{
					this.$apiDelete( url, sendData )
					// this.$apiPost( this.url, { _method: 'DELETE', locale: this.lang, file: event.item.name } )
						.then( (resp)=>
						{
							const data = resp.data;
							if (data.error)
							{
								console.error(data.error)
								return
							}
							else
								consola.debug(data.success)
							// consola.debug('file delete: ', resp)
							let idx = this.collection.indexOf(event.item)
							if ( idx > -1 )
							{
								let list = [ ...this.collection ];
								list.splice(idx, 1);
								this.collection = Object.freeze( list );
							}
							else
							{
								consola.debug('error: could not find collection item with index ', idx);
							}
						} )
						.catch( (error)=>
						{
							consola.debug('collection item delete error: ', error)
						} )
				}

			}
		},

		getSelectionPromise()
		{
			return new Promise( (resolve, reject) =>
			{
				this.resolve = resolve;
				this.reject = reject;
			} )
		},

		highlight(index)
		{
			this.selectedIndex = index;
			// consola.debug('clicked '+index);

		},

		select(index)
		{
			this.selectedIndex = index;
			// consola.debug('double-clicked '+index);
			consola.debug('clicked '+index);
			this.$eventBus.$emit('modal:resolve', this.filteredCollection[index])
			if (this.resolve)
			{
				// this.resolve({ item: this.collection[index], domEl: this.$refs.item[index] })
				this.resolve = null;
				this.reject = null;
			}
		},

		// emitClose()
		// {
		// 	this.$emit('resolve', null)
		// 	if (this.resolve)
		// 	{
		// 		// this.resolve(null)
		// 		this.resolve = null;
		// 		this.reject = null;
		// 	}
		// 	this.$emit('close')
		// }
	}
}
