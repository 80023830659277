//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import '@mdi/font/css/materialdesignicons.css'

import {
	// VApp,
	VIcon,
	VBtnToggle,
	VBtn,
	VTooltip,
	VSpacer,
	VCol,
	VRow,
	VContainer,
	VList,
	VListItem,
	VListItemTitle,
	VMenu,
} from 'vuetify/lib'

export default {
	name: 'admin-ui-lang-switcher',

	components:
	{
		VIcon,
		VBtnToggle,
		VBtn,
		VTooltip,
		VSpacer,
		VCol,
		VRow,
		VContainer,
		VList,
		VListItem,
		VListItemTitle,
		VMenu,
	},

	props:
	{
		value:
		{
			required: true,
		}
	},

	data()
	{
		return {
			buttonModel: 0,
		}
	},

	watch:
	{
		value(newVal)
		{
			consola.debug('lang-switcher value: ', newVal)
			this.buttonModel = newVal;
		},

		buttonModel(newVal)
		{
			consola.debug('lang-switcher buttonModel: ', newVal)
			this.onInput(newVal);
		},
	},

	computed:
	{
		...mapGetters(['getString', 'lang', 'langShort', 'allLanguages']),

		activeLanguages()
		{
			return this.allLanguages.filter(x => x.is_active)
		},

		selectedLangCode()
		{
			return this.allLanguages[this.buttonModel].lang_code;
		}
	},

	created()
	{
		consola.debug('lang-switcher created')
		this.buttonModel = this.value;
	},

	// mounted()
	// {
	// 	consola.debug('lang-switcher mounted')
	// },

	// beforeUpdate()
	// {
	// 	consola.debug('lang-switcher beforeUpdate')
	// },

	// updated()
	// {

	// },

	methods:
	{
		onInput(event)
		{
			// consola.debug('onInput: ', event);
			this.$emit('input', event);
		},

		setButton(idx)
		{
			consola.debug('setButton: ', idx)
			this.buttonModel = idx;

			// debugger;
			// for (let i = 0; i < this.$refs.btn.length; i++)
			// {
			// 	this.$refs.btn[i].isActive = (i == idx);
			// }
			// this.$refs.buttonGroup.$forceUpdate();
		}
	}
}
