//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import svgSymbol from '~/components/svg-symbol'

export default 
{
	name: 'cta-arrow',
	components: 
	{
		svgSymbol
	}
}
